import React, {useEffect, useState} from 'react'
import MainLayout from "../layouts/MainLayout/MainLayout";
import {useParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import {v4 as uuidv4, validate} from "uuid";
import axios from "axios";
import {trackEvent} from "../config/eventsTracking/tracking";
import {mixpanel_events} from "../config/eventsTracking/mixpanel_events";
import View from "../components/MD/View";
import CreatorsList from "../modules/experts/CreatorsList";
import AnimatedFeatures from "../modules/general/AnimatedFeatures";
import useViewport from "../hooks/useViewport";
import Typography from "../components/MD/Typography";
import Dialog from "../components/MD/Dialog/Dialog";
import {isMobile} from "react-device-detect";
import _ from "lodash";
import * as rdd from 'react-device-detect';



const LandingPage = () => {
   const params = useParams();
   const location = window.location
   const viewport = useViewport();
   const [cookies, setCookies] = useCookies()
   const [openContactUs, setOpenContactUs] = useState(false)

   useEffect(() => {
      (async () => {
         if(location?.pathname?.includes('/app')){
            //window.location.href = 'fantalab://'
         } else {
            //await initUser()
            let prov_id = uuidv4()
            let ref = localStorage.getItem('ref_2024') || cookies['ref_2024']
            if (!ref) {
               if(params?.id?.includes('_TT')){
                  let clone = _.cloneDeep(params?.id)
                  ref = clone?.replace('_TT', '')
               } else {
                  ref = params?.id
               }
               if (!ref) {
                  ref = null
               }
            }
            if (localStorage.getItem('prov_id_2024') || cookies['prov_id_2024']) {
               prov_id = localStorage.getItem('prov_id_2024') || cookies['prov_id_2024']
               if (!localStorage.getItem('prov_id_2024')) {
                  localStorage.setItem('prov_id_2024', prov_id)
               }
            } else {
               localStorage.setItem('prov_id_2024', prov_id)
            }
            await axios.post('https://api.fantalab.it/update_state', {
               id: prov_id,
               referral: ref,
               secondary: params?.id
            })

            trackEvent(mixpanel_events.sito_web_aperto, {referral: ref || 'organico', isMobile: isMobile})
            if (ref) {
               localStorage.setItem('ref_2024', ref)
               localStorage.setItem('ref_date', new Date().valueOf())
            }
            if (isMobile && params?.id && !params?.id?.includes('_TT')) {
               await axios.post('https://oyp3pq9208.execute-api.us-east-2.amazonaws.com/production/analytics/link-click', {
                  data: {ref: ref},
                  manufacturer: rdd.mobileVendor,
                  model: rdd.mobileModel,
                  os_version: rdd.osVersion,
                  device_type: rdd.deviceType,
                  screen_width: window.innerWidth,
                  link_id: prov_id,
                  id: prov_id,
                  platform: rdd.isIOS ? 'ios' : rdd.isAndroid ? 'android' : 'web',
                  browser: rdd.browserName,
               })
               //setReady(true)

               if (rdd.isAndroid) {
                  const startTime = Date.now();
                  // Try opening the app with the custom URL scheme
                  window.location = 'fantalab://refer?ref=' + encodeURIComponent(ref || '');

                  // Set a timeout to check if the app opened, and redirect to the Google Play Store if not
                  setTimeout(() => {
                     // Check if the current time is much greater than the start time, which suggests the app didn't open
                     if (Date.now() - startTime < 2000) {
                        // If the app opened within 2 second, do nothing.
                        return;
                     }
                     // Redirect to Google Play Store for Android after 2 second if the app didn't open
                     window.location = 'https://play.google.com/store/apps/details?id=com.fantalab.app&referrer=' + encodeURIComponent(prov_id || '');
                  }, 2500);
               } else if (rdd.isIOS) {
                  const startTime = Date.now();
                  // Try opening the app with the custom URL scheme
                  window.location = 'fantalab://refer?ref=' + encodeURIComponent(ref || '');

                  // Set a timeout to check if the app opened, and redirect to the App Store if not
                  setTimeout(() => {
                     // Check if the current time is much greater than the start time, which suggests the app didn't open
                     if (Date.now() - startTime < 2000) {
                        // If the app opened within 2 second, do nothing.
                        return;
                     }
                     // Redirect to App Store for iOS after 2 second if the app didn't open
                     window.location = 'https://apps.apple.com/it/app/id6450352620';
                  }, 2500);
               }
            }

            /*if (isMobile && params?.id && !params?.id?.includes('_TT')) {
               window.location.href = 'http://localhost:3000/app/' + params?.id
               //setReady(true)
            }*/
         }
      })()
   }, []);

   return (
      <MainLayout
         //bgColor={R.palette.white.main}
         //bgColor2={R.palette.dark.focus}
         horizontalPadding={0}
         emptyLayout
         verticalPadding={0}
         backgroundColorOverlay={0.75}
      >
         {
            (isMobile && params?.id && !params?.id?.includes('_TT')) ?
               null
               :
               <View
                  fullWidth
                  //maxHeight={'100vh'}
                  maxWidth={'100vw'}
                  flex1
                  overflow={'hidden'}
                  py={3}
                  centerCenter
               >
                  <Dialog title={'Contattaci'} fullWidth maxWidth={'sm'} open={openContactUs}
                          onClose={() => setOpenContactUs(false)}>
                     <View centerCenter flex1 spacing={1}>
                        <Typography align={'center'} variant={'h6'}>Per qualsiasi informazione contattaci
                           a:</Typography>
                        <Typography variant={"h5"}>info@fantalab.it</Typography>
                        {/*<Typography variant={'h6'}>oppure entra nel gruppo Telegram:</Typography>
                        <View clickable onClick={() => {
                           window.location = 'https://t.me/fantalab/1'
                        }}>
                           <Typography variant={"h5"} clickable>https://t.me/fantalab/1</Typography>
                        </View>*/}
                     </View>
                  </Dialog>
                  <View
                     centerCenter
                     clickable={"highlight"}
                     onClick={() => setOpenContactUs(true)}
                     style={{
                        width: 100,
                        height: 32,
                        position: 'absolute',
                        bottom: 20 + (viewport.isXS ? 110 : 0),
                        right: 20,
                        backgroundColor: 'white',
                        borderRadius: 30,
                        zIndex:10
                     }}>
                     <Typography variant={'body1'} oppositeColor>Contattaci</Typography>
                  </View>
                  <div style={{width: '100%'}}>
                     <CreatorsList/>
                  </div>
                  <View flex1 fullWidth centerCenter
                     //justify={'flex-end'}
                        maxWidth={1980} spacing={2}>
                     {/*<Logo size={viewport.isXS? 50 : 40}/>*/}
                     <AnimatedFeatures/>
                     {/*<SignunPanel/>*/}
                  </View>

               </View>
         }

         {/*<Footer/>*/}
      </MainLayout>
   )
}

export default LandingPage

