import {useEffect, useState} from "react";
import MainLayout from "../layouts/MainLayout/MainLayout";
import Button from "../components/MD/Button";
import axios from "axios";
import * as rdd from 'react-device-detect';
import {useParams} from "react-router-dom";
import {v4 as uuidv4, validate} from "uuid";


const RedirectPage = () => {
    const params = useParams();
    const [openAppLink, setOpenAppLink] = useState(null)

    useEffect(() => {
        (async () => {
            if (rdd.isMobile) {
                let id = params?.id
                if (validate(id)) {
                    await axios.post('https://oyp3pq9208.execute-api.us-east-2.amazonaws.com/production/analytics/link-click', {
                        data: {id},
                        manufacturer: rdd.mobileVendor,
                        model: rdd.mobileModel,
                        os_version: rdd.osVersion,
                        device_type: rdd.deviceType,
                        screen_width: window.innerWidth,
                        link_id: id,
                        id: id,
                        platform: rdd.isIOS ? 'ios' : rdd.isAndroid ? 'android' : 'web',
                        browser: rdd.browserName,
                    })
                    if (rdd.isAndroid) {
                        const startTime = Date.now();
                        // Try opening the app with the custom URL scheme
                        window.location = 'fantalab://smart-link?id=' + id
                        setOpenAppLink('smart-link?id=' + id)
                        // Set a timeout to check if the app opened, and redirect to the Google Play Store if not
                        setTimeout(() => {
                            // Check if the current time is much greater than the start time, which suggests the app didn't open
                            if (Date.now() - startTime < 2000) {
                                // If the app opened within 2 second, do nothing.
                                return;
                            }
                            // Redirect to Google Play Store for Android after 2 second if the app didn't open
                            window.location = 'https://play.google.com/store/apps/details?id=com.fantalab.app&referrer=' + encodeURIComponent(id || '');
                        }, 2500);
                    } else if (rdd.isIOS) {
                        const startTime = Date.now();
                        // Try opening the app with the custom URL scheme
                        window.location = 'fantalab://smart-link?id=' + id
                        setOpenAppLink('smart-link?id=' + id)
                        // Set a timeout to check if the app opened, and redirect to the App Store if not
                        setTimeout(() => {
                            // Check if the current time is much greater than the start time, which suggests the app didn't open
                            if (Date.now() - startTime < 2000) {
                                // If the app opened within 2 second, do nothing.
                                return;
                            }
                            // Redirect to App Store for iOS after 2 second if the app didn't open
                            window.location = 'https://apps.apple.com/it/app/id6450352620';
                        }, 2500);
                    }
                } else {
                    let prov_id = localStorage.getItem('prov_id_2024')
                    if (!prov_id) {
                        prov_id = uuidv4()
                        localStorage.setItem('prov_id_2024', prov_id)
                    }
                    let ref = localStorage.getItem('ref_2024') || params?.id
                    await axios.post('https://oyp3pq9208.execute-api.us-east-2.amazonaws.com/production/analytics/link-click', {
                        data: {ref},
                        manufacturer: rdd.mobileVendor,
                        model: rdd.mobileModel,
                        os_version: rdd.osVersion,
                        device_type: rdd.deviceType,
                        screen_width: window.innerWidth,
                        link_id: prov_id,
                        id: prov_id,
                        platform: rdd.isIOS ? 'ios' : rdd.isAndroid ? 'android' : 'web',
                        browser: rdd.browserName,
                    })
                    if (rdd.isAndroid) {
                        const startTime = Date.now();
                        // Try opening the app with the custom URL scheme
                        window.location = 'fantalab://refer?ref=' + encodeURIComponent(ref || '');
                        setOpenAppLink('refer?ref=' + ref)
                        // Set a timeout to check if the app opened, and redirect to the Google Play Store if not
                        setTimeout(() => {
                            // Check if the current time is much greater than the start time, which suggests the app didn't open
                            if (Date.now() - startTime < 2000) {
                                // If the app opened within 2 second, do nothing.
                                return;
                            }
                            // Redirect to Google Play Store for Android after 2 second if the app didn't open
                            window.location = 'https://play.google.com/store/apps/details?id=com.fantalab.app&referrer=' + encodeURIComponent(prov_id || '');
                        }, 2500);
                    } else if (rdd.isIOS) {
                        const startTime = Date.now();
                        // Try opening the app with the custom URL scheme
                        window.location = 'fantalab://refer?ref=' + encodeURIComponent(ref || '');
                        setOpenAppLink('refer?ref=' + ref)
                        // Set a timeout to check if the app opened, and redirect to the App Store if not
                        setTimeout(() => {
                            // Check if the current time is much greater than the start time, which suggests the app didn't open
                            if (Date.now() - startTime < 2000) {
                                // If the app opened within 2 second, do nothing.
                                return;
                            }
                            // Redirect to App Store for iOS after 2 second if the app didn't open
                            window.location = 'https://apps.apple.com/it/app/id6450352620';
                        }, 2500);
                    }
                }
            } else {
                let id = params?.id
                let res = await axios.post('https://oyp3pq9208.execute-api.us-east-2.amazonaws.com/production/smart-link/get-data', {
                    id: id
                })
                if (res.data?.data?.url) {
                    window.location.replace(res.data?.data?.url)
                } else {
                    window.location.replace('https://fantalab.it')
                }
            }
        })()

    }, [])

    return (
        <MainLayout
            //bgColor={R.palette.white.main}
            //bgColor2={R.palette.dark.focus}
            horizontalPadding={0}
            emptyLayout
            verticalPadding={0}
            backgroundColorOverlay={0.75}
        >
            {rdd.isMobile && <Button onClick={() => {
                if (openAppLink) {
                    window.location = 'fantalab://' + openAppLink
                    setTimeout(() => {
                        if (rdd.isAndroid) {
                            window.location = 'https://play.google.com/store/apps/details?id=com.fantalab.app&&referrer=' + params?.id
                        } else {
                            window.location = 'https://apps.apple.com/it/app/id6450352620'
                        }
                    }, 2000)
                } else {
                    if (rdd.isAndroid) {
                        window.location = 'https://play.google.com/store/apps/details?id=com.fantalab.app'
                    } else {
                        window.location = 'https://apps.apple.com/it/app/id6450352620'
                    }
                }
            }}>Apri App</Button>}
        </MainLayout>
    )
}
export default RedirectPage
